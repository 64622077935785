<template>
  <div>
    <div class="dropzone" 
        v-cloak 
        v-bind:class="{ dropzone: true, dragover: dragover }"
        @drop.prevent="addFile" 
        @dragover.prevent 
        @dragenter="dragover = true" 
        @dragleave="dragover = false"
        @click="openDialog()">

      {{ caption || 'Click here or drag and drop your files here to upload' }}
    </div>

    <b-alert v-model="uploadProblemSecs" variant="danger" dismissible>
      {{ uploadProblems }}
    </b-alert>
  </div>
</template>

<script>
  export default {
    name: 'FileUpload',
    components: {
    },
    props: {
      caption: String,
      multiple: {
        type: Boolean,
        default: true,
      },
      url: String,
    },
    data () {
      return {
        dragover: false,
        invalidFiles: [],
        uploadProblemSecs: 0,
        uploadProblems: '',
        uploading: false,
        statusText: {
          uploading: "Uploading",
          uploadComplete: "Upload ready",
          uploadError: "Upload error",
        }
      }
    },
    methods: {
      openDialog () {
        const fileSelector = document.createElement("input")
        fileSelector.setAttribute('type', 'file')
        if (this.multiple) {
          fileSelector.setAttribute('multiple', 'multiple')
        }
        fileSelector.addEventListener('change', (e) => {
          this.addFile(e, fileSelector.files)
        })
        fileSelector.click()
      },
      addFile (e, files) {
        this.dragover = false
        let droppedFiles;

        if (files) {
          droppedFiles = files
        } else {
          droppedFiles = e.dataTransfer.files
        }
        
        if (!droppedFiles) {
          return
        }

        if (this.multiple == false && droppedFiles.length > 1) {
          alert('Please upload one file')
        }

        for (const f of [...droppedFiles]) {
          f.status = ''
          f.uploadPercentage = 0
          f.uploadComplete = false
          f.extension = f.name.split('.').pop().toLowerCase()
            
          this.$emit('update', f, false)
          this.uploadFile(f);
        }

      },
      uploadFile (file) {
        let formData = new FormData()
        formData.append('file', file)
        file.status = 'uploading'

        const onUploadProgress = (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              file.uploadPercentage = percentCompleted
              this.$emit('update', file, false)
        }

        this.$http.post(this.url + encodeURIComponent(file.name),
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: onUploadProgress
          }
        ).then(() => {
          file.uploadComplete = true
          this.$emit('update', file, true)
        })
        .catch(e => {
          console.log('Upload error', e)
          file.status = 'uploadError'
          this.$emit('update', file, false)
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables';

  .dropzone {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    border: 2px dashed $color-blue;
    border-radius: 5px;
    background-color: #282933;
    margin-bottom: 20px;

    &.dragover {
      border-style: solid;
      background-color: #414353;
    }
  }
</style>